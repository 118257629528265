import { getFunctions, httpsCallable } from "firebase/functions";

export const saveEmail = async (email) => {
  console.log("Submitting email:", email);

  try {
    const response = await fetch("https://us-central1-yusef-b9696.cloudfunctions.net/addEmail", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        data: {
          email: email,
        },
      }),
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: "No JSON response" }));
      console.error("Error response from server:", errorData);
    } else {
      const data = await response.json();
      console.log("Success:", data);
    }

  } catch (error) {
    console.error("Error saving email:", error);
  }
};
