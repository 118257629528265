import React, { useRef, useEffect, useState } from "react";
import video3 from "../Videos/YusefLandingPagePromo1.mp4";
import video2 from "../Videos/YusefLandingPagePromo2.mp4";
import video1 from "../Videos/KingOutsideVIsualizerCompressed.mp4";
import YusefOpening from "../Videos/YusefOpeningFinal.mp4";

import noDestinationLogo from "../Images/NoDestinationLogo.png"
import spiritWaveLogo from "../Images/SpiritWaveLogo.png"
import { gsap } from "gsap"; // Import GSAP


import "../CSS/Styles/Typography.css";
import "../CSS/Styles/ColorTheme.css";
import "../CSS/Pages/LandingPage.css";
import "../CSS/Components/ExpandableBox.css";
import "../CSS/Components/CarouselBoxAlignLeft.css";
import "../CSS/Components/Loading.css";

import "../CSS/Layouts/Home.css";
import "../CSS/Layouts/About.css";
import "../CSS/Layouts/Store.css";
import "../CSS/Layouts/Connect.css";
import SideMenu from "../components/SideMenu.js";
import HomeLogo from "../components/HomeLogo.js";
import Footer from "../components/Footer.js";
import Store from "../Pages/Store.js";
import Connect from "../Pages/Connect.js";
import About from "../Pages/About.js";
import ExpandableBox from "../components/ExpandedBox.js";
import Hamburger from "hamburger-react";


import CarouselAlignLeft from "../components/CarouselAlignLeft.js"; // Adjust the path as necessary
import CarouselAlignCenter from "../components/CarouselAlignCenter.js"; // Adjust the path as necessary

const videos = [
  { id: "video1", src: video1 },
  { id: "video2", src: video2 },
  { id: "video3", src: video3 },
];

const logos = [
  { id: "video1", src: noDestinationLogo },
  { id: "video2", src: spiritWaveLogo },
  { id: "video2", src: noDestinationLogo },

];



function LandingPage() {

  const videoRefs = useRef([]);
  const sectionRefs = useRef([]);
  const landingVideoRef = useRef(null);
  const [scrollInProgress, setScrollInProgress] = useState(false);

  const [currentSection, setCurrentSection] = useState("home");
  const [lastVideoSection, setLastVideoSection] = useState(videos[0].id);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [hasEntered, setHasEntered] = useState(false); // Track if user clicked "Enter"

  const SplitType = window.SplitType;
  const gsap = window.gsap;

    // Split text using SplitType
  const loadingText = new SplitType(".loading-text.initial", { types: "chars" });
  const completeText = new SplitType(".loading-text.complete", {
    types: "chars"
  });

  const videoDesc = [
    { id: "video1", desc: 
      <div className="videoDesc fineBody white">
      <strong className="noDestinationTitle">A conceptual music project</strong> <br/>
      that follows the journey of a young artist named  
      <strong className="noDestinationTitle "> Yusef...</strong><br/><br/>
      </div>
     },
    { id: "video2", desc: 
      <div className="videoDesc fineBody white">
      <strong style={{fontSize: "2rem", paddingTop: "2vh"}}>A SPIRITUAL EXPOSE</strong> <br/>
      dedicated to creating a safe and inclusive space for everyone <br/>
      to express and enjoy themselves  
      </div>
     },
    { id: "video2", desc: 
      <div className="videoDesc fineBody white">
      <strong className="noDestinationTitle">A conceptual music project</strong> <br/>
      that follows the journey of a young artist named  
      <strong className="noDestinationTitle "> Yusef...</strong><br/><br/>
      </div>
     },
  
  ];

  // Initial states
  gsap.set(".loading-text.complete", { y: "100%" });
  gsap.set(loadingText.chars, { opacity: 0, y: 100 });
  gsap.set(completeText.chars, { opacity: 0, y: 100 });

  // Animate in loading text
  gsap.to(loadingText.chars, {
    opacity: 1,
    y: 0,
    duration: 0.5,
    stagger: 0.05,
    ease: "power2.out"
  });

  const colorStages = [
    { bg: "rgb(98, 86, 53)", text: "rgb(230, 225, 215)" },
    { bg: "rgb(200, 180, 160)", text: "rgb(230, 225, 215)" },
    { bg: "rgb(230, 170, 42)", text: "rgb(230, 225, 215)" }
  ];

  function updateColors(progress) {
    const stage = Math.floor(progress / 25);
    if (stage < colorStages.length) {
      document.querySelector(".preloader").style.backgroundColor =
        colorStages[stage].bg;
      document.querySelector(".progress-bar").style.backgroundColor =
        colorStages[stage].text;
      document
        .querySelectorAll(".loading-text .char, .percentage")
        .forEach((el) => {
          el.style.color = colorStages[stage].text;
        });
    }

    if (progress === 100) {
      const completeTextEl = document.querySelector(".loading-text.complete");
      if (completeTextEl) {
        console.log("Updating text to 'Enter'..."); // Debugging log
        completeTextEl.innerHTML = "Enter"; // Update content directly
        
        // Clear previous SplitType instance if any
        completeTextEl.innerHTML = `<span class="char">E</span><span class="char">n</span><span class="char">t</span><span class="char">e</span><span class="char">r</span>`;
        
        console.log("SplitType reinitialized for 'Enter' text."); // Debugging log
  
        // Apply GSAP animations to the new "Enter" text
        gsap.set(completeTextEl.querySelectorAll(".char"), { opacity: 0, y: 100 });
        gsap.to(completeTextEl.querySelectorAll(".char"), {
          opacity: 1,
          y: 0,
          duration: 0.3,
          stagger: 0.03,
          ease: "power2.out",
        });
      } else {
        console.error("Complete text element not found."); // Debugging log
      }
    }
  
  }

  const tl = gsap.timeline({ paused: true });

  tl.to(".progress-bar", {
    width: "100%",
    duration: 5,
    ease: "power1.inOut",
    onUpdate: function () {
      const progress = Math.round(this.progress() * 100);
      document.querySelector(".percentage").textContent = progress;
      updateColors(progress);
    },
  })
    .to(".loading-text.initial", {
      y: "-100%",
      duration: 0.5,
      ease: "power2.inOut",
    })
    .to(
      ".loading-text.complete",
      {
        y: "0%",
        duration: 0.5,
        ease: "power2.inOut",
      },
      "<"
    )
    .to(
      completeText.chars,
      {
        opacity: 1,
        y: 0,
        duration: 0.3,
        stagger: 0.03,
        ease: "power2.out",
      },
      "<0.2"
    );

    document.addEventListener("click", () => {
      if (document.querySelector(".percentage").textContent === "100") {
        tl.to(".preloader", {
          y: "-100vh",
          duration: 1,
          ease: "power2.inOut",
          onComplete: () => {

            setHasEntered(true);
                            
            gsap.set(".content", { visibility: "visible" });
            playCurrentVideo()

            tl.kill(); // Cleanup timeline

            

          },
        });
      }
    });
    
    // Start Timeline
    tl.play();
    

  useEffect(() => {
    // Function to check screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // For screens smaller than 768px
    };

    // Check screen size on mount
    handleResize();
    updateViewportHeight();

    // Add event listener to track screen resize
    window.addEventListener("resize", handleResize);
    window.addEventListener("resizeHight", updateViewportHeight);

    const handleScroll = () => {
      console.log("Scroll Y position:", window.scrollY);

    };

    window.addEventListener("scroll", handleScroll);

    // Listen for the resize event to update the value when the viewport height changes
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up the listener on component unmount

      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const element = entry.target;
          if (entry.isIntersecting) {
            setCurrentSection(element.id);
            if (element.tagName === "VIDEO") {
              if (element.paused && element.currentTime > 0) {
                element.currentTime = 0;
              }
              element.play().catch((error) => {
                console.error("Error attempting to play:", error);
              });
            }
          } else {
            if (element.tagName === "VIDEO") {
              element.pause();
            }
          }
        });
      },
      {
        threshold: [0.1], // Lower threshold to detect more section entries
        rootMargin: "0px 0px -50% 0px", // Adjust for better section recognition
      }
    );

    const landingObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const element = entry.target;
          if (entry.isIntersecting) {
            if (element.tagName === "VIDEO") {
              if (element.paused && element.currentTime > 0) {
                element.currentTime = 0;
              }
              element.play().catch((error) => {
                console.error("Error attempting to play:", error);
              });
            }
          } else {
            if (element.tagName === "VIDEO") {
              element.pause();
            }
          }
        });
      },
      {
        threshold: [0.1], // Lower threshold to detect more section entries
        rootMargin: "0px 0px -50% 0px", // Adjust for better section recognition
      }
    );



    [...videoRefs.current, ...sectionRefs.current].forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    landingObserver.observe(landingVideoRef.current);


    const handleVideoEnd = (index) => {
      console.log("VIDEO ENDED!!!");

      if (index < videoRefs.current.length - 1) {
        sectionRefs.current[index + 1].scrollIntoView({ behavior: "smooth" });
        console.log("VIDEO ENDED!!! REGG");
        console.log(`VIDEO ENDED!!! index: ${sectionRefs.current.length})`);

      } else if (index === 4){

        console.log("VIDEO ENDED!!! VISION");
        document.getElementById("vision").scrollIntoView({ behavior: "smooth" });
      }else {

        console.log("VIDEO ENDED!!! ABOUT");
        document.getElementById("about").scrollIntoView({ behavior: "smooth" });
      }
    };
    

    videoRefs.current.forEach((video, index) => {
      video.addEventListener("ended", () => handleVideoEnd(index));
    });

    landingVideoRef.current.addEventListener("ended", () => handleVideoEnd(4));


    document.querySelectorAll(".see-more-less").forEach((button) => {
      // Check if there is already an event listener attached
      const hasListener = button.dataset.listenerAttached === "true";
      if (!hasListener) {
        button.addEventListener("click", function (e) {
          e.preventDefault();
          // Find the expandable box by navigating the DOM
          let expandableBox = button.closest(".expandable-box"); // Use closest() to find the nearest .expandable-box
          const aboutSection = document.querySelector(".about-section");

          if (expandableBox) {
            // Corrected: No parentheses
            if (expandableBox.classList.contains("expanded")) {
              expandableBox.classList.remove("expanded");
              this.textContent = "SEE MORE";
              console.log("seemore!");
            } else {
              expandableBox.classList.add("expanded");
              this.textContent = "SEE LESS";
              console.log("seeless!");
            }
            const reflow = aboutSection.offsetHeight; // Force reflow

            button.classList.add("faded");       
            setTimeout(() => {
              button.classList.remove("faded");
            }, 500);
          }
        });
        // Mark this button as having a listener attached
        button.dataset.listenerAttached = "true";
      }
    });
    

    return () => {
      if (!videoRefs) {
        [...videoRefs.current, ...sectionRefs.current].forEach((element) => {
          if (element) {
            observer.unobserve(element);
          }
        });
        landingVideoRef.removeEventListener("ended", handleVideoEnd);

        videoRefs.current.forEach((video) => {
          video.removeEventListener("ended", handleVideoEnd);
        });
      }
    };
  }, []);


  const playCurrentVideo = () => {
    const videoData = videos.find((video) => video.id === lastVideoSection);
    const index = videos.indexOf(videoData);
  
    if (index !== -1 && videoRefs.current[index]) {
      const currentVideo = videoRefs.current[index];
  
      // Check if the video is in view
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Play video if it is in view
              currentVideo
                .play()
                .then(() => console.log("Playing video in view:", lastVideoSection))
                .catch((err) => console.error("Error playing video:", err));
            } else {
              // Pause video if it goes out of view
              currentVideo.pause();
              console.log("Paused video, not in view:", lastVideoSection);
            }
          });
        },
        {
          threshold: 0.5, // Video must be at least 50% in view
        }
      );
  
      // Observe the current video
      observer.observe(currentVideo);
    } else {
      console.error(
        "Invalid lastVideoSection or no video found:",
        lastVideoSection,
        videoRefs.current
      );
    }
  };


  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const SCROLL_DURATION = 1000; // Adjust duration for smooth transitions
    let lastScroll = 0;
  
    const throttleScroll = (func, limit) => {
      return function (...args) {
        const now = Date.now();
        if (now - lastScroll >= limit) {
          lastScroll = now;
          func(...args);
        }
      };
    };
  
    const handleWheel = (event) => {
      if (scrollInProgress) {
        console.log("Scroll in progress, ignoring scroll."); // Debugging output
        return;
      }
  
      event.preventDefault();
      setScrollInProgress(true); // Set flag to block additional scrolls
  
      const currentIndex = [...sections].findIndex(
        (section) => section.id === currentSection
      );
  
      const nextSection =
        event.deltaY > 0
          ? sections[currentIndex + 1]
          : sections[currentIndex - 1];
  
      if (nextSection) {
        console.log(`Scrolling to section: ${nextSection.id}`); // Debugging output
        nextSection.scrollIntoView({ behavior: "smooth" });
        setCurrentSection(nextSection.id);
  
        setTimeout(() => {
          setScrollInProgress(false) // Reset flag after transition
          console.log("Transition complete, ready for next scroll."); // Debugging output
        }, SCROLL_DURATION);
      } else {
        console.log("No more sections to scroll."); // Debugging output
        setScrollInProgress(false) // Reset flag if no next section
      }
    };
      const videoSections = document.querySelector(".contentV");
  
    const updateSectionTransform = () => {
      const videoIndex = videos.findIndex((v) => v.id === currentSection);
  
      if (videoIndex < videos.length && videoIndex !== - 1){
        setLastVideoSection(currentSection)

      }
      console.log(`Current currentSection: ${currentSection}`);

      console.log(`Current video videoIndex: ${videoIndex}`);
      console.log(`Current lastVideoSection: ${lastVideoSection}`);

      // console.log(`We Hit!! nextSection.id ${nextSection.id}`);
      if (videoIndex !== -1) {
        // videoSections.forEach((section, index) => {
          if (videoSections) {
            videoSections.style.transform = `translateX(${(videoIndex) * 100}vw)`;
          } else {
            // console.error(`Section at index ${index} is undefined.`);
          }
        // });
      }
    };
    
    updateSectionTransform(); // Call initially
    const throttledWheel = throttleScroll(handleWheel, SCROLL_DURATION);
    window.addEventListener("wheel", throttledWheel, { passive: false });

    return () => {
      sections.forEach((section) => {
        section.removeEventListener("wheel", handleWheel);
      });
    }; 
  }, [currentSection, scrollInProgress]);

  const [itemSection, setItemSection] = useState("home");

  useEffect(() => {
    const overlay = document.getElementById("video-overlay"); // Correct ID selection

    if (overlay) {
      // Cleanup: Remove all relevant classes
      overlay.classList.remove(
        "homeShade",
        "aboutShade",
        "storeShade",
        "connectShade"
      );

      // Determine the current section and add the appropriate class
      if (
        currentSection === "video1" ||
        currentSection === "video2" ||
        currentSection === "video3"
      ) {
        setItemSection("home");
        overlay.classList.add("homeShade");
      } else if (
        currentSection === "landingVideo" ||
        currentSection === "why" ||
        currentSection === "vision" ||
        currentSection === "values"
      ) {
        setItemSection("about");
        overlay.classList.add("aboutShade");
      } else if (currentSection === "store") {
        setItemSection("store");
        overlay.classList.add("storeShade");
      } else {
        setItemSection("connect");
        overlay.classList.add("connectShade");
      }
    } else {
      console.error("Overlay element not found"); // Log if overlay is null
    }

    const sections = Array.from(document.querySelectorAll("section"));
    sectionRefs.current = sections;

    let startX, startY, endX, endY;

    // Function to handle swipe direction
    const handleGesture = () => {
      const deltaX = endX - startX;
      const deltaY = endY - startY;

      // Swipe up or down to move between sections
      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        if (deltaY < -50 ) {
          console.log("Swipe Up Detected"); // Check for swipe up

          goToNextSection(); // Swipe up
        } else if (deltaY > 50) {
          console.log("Swipe Down Detected"); // Check for swipe down

          goToPreviousSection(); // Swipe down
        }
      } else {
        // Horizontal swipes (Left/Right)
        if (deltaX < -150) {
          console.log("Swipe Left Detected");
          goToNextSection(); // Swipe left moves forward
        } else if (deltaX > 150) {
          console.log("Swipe Right Detected");
          goToPreviousSection(); // Swipe right moves backward
        }
      }
    };

    const goToNextSection = () => {
      const currentIndex = sections.findIndex(
        (section) => section.id === currentSection
      ); // Find index of current section
      console.log("Current section index:", sections); // Log the index of the current section
      if (currentIndex < sections.length - 1) {
        const nextSection = sections[currentIndex + 1];
        console.log("Navigating to next section:", nextSection.id); // Log next section
        nextSection.scrollIntoView({ behavior: "smooth" });
        setCurrentSection(nextSection.id); // Update state for current section

        const videoIndex = videos.findIndex((v) => v.id === currentSection);
  
        if (videoIndex < videos.length && videoIndex !== - 1){
          setLastVideoSection(currentSection)
  
        }
      } else {
        console.log("Already at the last section");
      }
    };

    // Function to navigate to the previous section
    const goToPreviousSection = () => {
      const currentIndex = sections.findIndex(
        (section) => section.id === currentSection
      ); // Find index of current section
      console.log("Current section index:", currentIndex); // Log the index of the current section
      if (currentIndex > 0) {
        const prevSection = sections[currentIndex - 1];
        const videoIndex = videos.findIndex((v) => v.id === currentSection);
        console.log("Navigating to previous section:", prevSection.id); // Log previous section

        
        if (prevSection.id === videos[videos.length - 1].id){
          console.log(`Current video index: ${videoIndex}`);
    
          setLastVideoSection(videoIndex - 1)
          const videoSection = [...sections].findIndex((v) => v.id === lastVideoSection);

          sections[videoSection].scrollIntoView({
            behavior: 'smooth',
            block: 'start', // Ensures scrolls to the top of the section
          }); 

          setCurrentSection(videoIndex - 1); // Update state for current section

        } else {
          prevSection.scrollIntoView({ behavior: "smooth" });
          setCurrentSection(prevSection.id); // Update state for current section

        }
      } else {
        console.log("Already at the first section");
      }
    };

    // Event listeners for touch events
    const handleTouchStart = (event) => {
      console.log("Touch Start:", startX, startY); // Check if this is triggered

      const touch = event.touches[0];
      startX = touch.clientX;
      startY = touch.clientY;
    };

    const handleTouchEnd = (event) => {
      const touch = event.changedTouches[0];
      endX = touch.clientX;
      endY = touch.clientY;
      handleGesture(); // Detect swipe after touch ends
    };

    // Add event listeners for touch gestures
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);

      if (overlay) {
        overlay.classList.remove(
          "homeShade",
          "aboutShade",
          "storeShade",
          "connectShade"
        );
      }

      // window.removeEventListener('scroll', handleScroll);
    };
  }, [currentSection, lastVideoSection]);

  function updateViewportHeight() {
    // Calculate 1% of the viewport height (taking into account mobile menu bars)
    const vh = window.innerHeight * 0.01;
    // Set the custom property --vh based on this value
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

 useEffect(() => {
    const aboutDiv = document.getElementById("about");
    const visionSection = document.getElementById("vision");

    let timeoutId = null;

    const observerVision = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            timeoutId = setTimeout(() => {
              aboutDiv.classList.add("about-vision-active");
              console.log("VISION is active");
            }, 100);
          } else {
            aboutDiv.classList.remove("about-vision-active");
            console.log("VISION is not active");
          }
        });
      },
      {
        root: null, // observing for viewport
        rootMargin: "0px",
        threshold: 0.1, // adjust threshold to your needs
      }
    );

    observerVision.observe(visionSection);

    clearTimeout(timeoutId); // Clean up the timeout
    return () => observerVision.disconnect() 
  }, []); 

  useEffect(() => {
    const aboutDiv = document.getElementById("about");
    const landingVideoSection = document.getElementById("landingVideo");

    let timeoutId = null;

    const observerLandingVideo = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            timeoutId = setTimeout(() => {
              aboutDiv.classList.add("landingVideo-vision-active");
              console.log("VISION is active");
            }, 100);
          } else {
            aboutDiv.classList.remove("landingVideo-vision-active");
            console.log("VISION is not active");
          }
        });
      },
      {
        root: null, // observing for viewport
        rootMargin: "0px",
        threshold: 0.1, // adjust threshold to your needs
      }
    );

    observerLandingVideo.observe(landingVideoSection);

    clearTimeout(timeoutId); // Clean up the timeout
    return () => observerLandingVideo.disconnect() 
  }, []); 

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in-bottom"); // Add the class when in view
          } else {
            entry.target.classList.remove("fade-in-bottom"); // Remove the class when out of view
          }
        });
      },
      {
        root: null,
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    // Select all <strong> elements to observe
    const strongElements = document.querySelectorAll("strong");
    strongElements.forEach((el) => {
      observer.observe(el);
    });

    // Cleanup the observer when the component is unmounted
    return () => {
      strongElements.forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, []);

  useEffect(() => {}, []);


  // Handle input change
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  // Handle form submission
  const handleSubmit = () => {
    if (email) {
      console.log(`Subscribed with email: ${email}`);
      alert(`Subscribed successfully with email: ${email}`);
      // Here, you would typically send the email to your server or API for processing
      setEmail(""); // Clear the input after submission
    } else {
      alert("Please enter a valid email address.");
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen); // Toggle the menu state
  };

  // window.addEventListener('scroll', () => {
  //   const sections = document.querySelectorAll('section');
  //   let currentSection = 0;

  //   sections.forEach((section, index) => {
  //     const rect = section.getBoundingClientRect();
  //     if (rect.top <= window.innerHeight && rect.bottom >= 0) {
  //       currentSection = index;
  //     }
  //   });

  //   window.scrollTo({
  //     top: sections[currentSection].offsetTop,
  //     behavior: 'smooth'
  //   });
  // });

  return (
    <div id="landing-Page">
      
      {isMobile === false ? (
        <div>
          <div class="preloader">
        <div class="progress-container">
          <div class="progress-bar"></div>
        </div>
        <div class="text-container">
          <div class="loading-text initial">Loading</div>
          <div class="loading-text complete">Complete</div>
        </div>
        <div class="percentage">0</div>
        
      </div>
          <HomeLogo currentSection={[currentSection, itemSection, isMobile]} style={{ position: "fixed", zIndex: "1002" }}/>

          <SideMenu style={{ position: "fixed", zIndex: "100" }}
            currentSection={[currentSection, itemSection, isMobile, lastVideoSection, videos]}
            toggleMenu={toggleMenu}
            isMenuOpen={isMenuOpen}
          />
          <Footer currentSection={[currentSection, itemSection, isMobile]} />
        </div>
      ) : (
        <div style={{ position: "fixed", zIndex: "1001" }}>
          <div class="preloader">
        <div class="progress-container">
          <div class="progress-bar"></div>
        </div>
        <div class="text-container">
          <div class="loading-text initial">Loading</div>
          <div class="loading-text complete">Complete</div>
        </div>
        <div class="percentage">0</div>
        
      </div>
          <HomeLogo currentSection={[currentSection, itemSection, isMobile]} style={{ position: "fixed", zIndex: "1002" }}/>


          {isMenuOpen === false ? (
            <Hamburger
              toggled={isMenuOpen}
              toggle={toggleMenu}
              easing="ease-in"
              color={
                currentSection === "why" ||
                currentSection === "values" ||
                currentSection === "connect"
                  ? "black"
                  : "white"
              }
            />
          ) : (
            <Hamburger
              toggled={isMenuOpen}
              toggle={toggleMenu}
              easing="ease-in"
              color="white"
            />
          )}

          <div className={`phone-menu ${isMenuOpen ? "open" : ""}`}>

            <SideMenu style={{ position: "fixed", zIndex: "1000" }}
              currentSection={[currentSection, itemSection, isMobile]}
              toggleMenu={toggleMenu}
              isMenuOpen={isMenuOpen}
            />
            <Footer currentSection={[currentSection, itemSection, isMobile]} />
          </div>
        </div>
      )}
      <div id="video-overlay"></div> {/* Overlay added here */}
      <div id="home">
        {videos.map((video, index) => (
          <section
            key={video.id}
            id={video.id}
            className="video-section"
            ref={(el) => (sectionRefs.current[index] = el)}
          >
            <div className="videoBlockInfo">
              <div className="videoIcon">
                <img src={logos[index].src} alt={logos[index].id}/>
              </div>
                {videoDesc[index].desc}
              <div className="videoButton white primaryBody">
                {index !== 1 ? (
                  <a href={`#store`} style={{color:"white", fontFamily:"nougat-script", textDecoration: 'none', fontWeight: 'bold' }}>
                  LEARN MORE
                </a>) : (
                  <a href="/comingsoon" style={{color:"white", fontFamily:"nougat-script", textDecoration: 'none', fontWeight: 'bold' }}>
                LEARN MORE
                </a>)}
              
              </div>
            </div>
            <video
              ref={(el) => (videoRefs.current[index] = el)}
              src={video.src}
              loop={false}
              autoPlay
              controls={false}
              className="video-item"
              disablePictureInPicture
              playsInline // Ensures it plays inline on iPhone instead of forcing fullscreen
            />
          </section>
        ))}
      </div>
      <div className="infoContent">
        <div id="about" className="contentV">
          <section
            id="landingVideo"
            className="about-section"
            ref={(el) => (sectionRefs.current[3] = el)}
          >
            <video
              ref={landingVideoRef}
              src={YusefOpening}
              loop={false}
              autoPlay
              controls={false}
              className="video-item"
              disablePictureInPicture
              playsInline // Ensures it plays inline on iPhone instead of forcing fullscreen
            />
          </section>
          <section
            className="about-section"
            id="vision"
            ref={(el) => (sectionRefs.current[4] = el)}
          >
            <div className="about-content left">
              <div class="box-header white" style={{ marginLeft: "10%", }}>
                <h1 style={{margin: "0vh"}}>VISION</h1>
              </div>
              <CarouselAlignLeft />
            </div>
          </section>
          <section
            className="about-section"
            id="why"
            ref={(el) => (sectionRefs.current[5] = el)}
          >
            <ExpandableBox />
          </section>
          
          <section
            className="about-section"
            id="values"
            ref={(el) => (sectionRefs.current[6] = el)}
          >
            <div className="about-content">
              <h1 class="box-header black importantBody">VALUES</h1>
              <CarouselAlignCenter />
              <div class="box-footer fineBody black">
                <h2>
                  GOD INCREASES
                  <br />
                  -YUSEF
                </h2>
              </div>
            </div>
          </section>

          <section id="store" className="contentV" ref={(el) => (sectionRefs.current[7] = el)}>
            <Store />
          </section>
          <section id="connect" className="contentV" ref={(el) => (sectionRefs.current[8] = el)}>
            <Connect />
          </section>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
